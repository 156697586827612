<template>
  <div class="app-container">
    <div class="top" style="margin:10px 0">
      <el-button type="success" icon="el-icon-plus" @click="showAdd"
        >新增权限</el-button
      >
    </div>
    <el-table
      :data="listData"
      style="width: 100%;margin-bottom: 20px; text-align:center"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'child', hasChildren: 'hasChildren' }"
    >
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column
        prop="name"
        label="标识"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="description"
        label="名称"
        align="center"
      ></el-table-column>

      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="showEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="handleDel(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :title="handelType === 'new' ? '新增权限' : '编辑权限'"
      :visible.sync="dialogVisible"
      width="800px"
      center
    >
      <el-form
        ref="ruleForm"
        :model="form"
        label-width="100px"
        :inline="true"
        class="inlineForm"
      >
        <el-form-item label="标识">
          <el-input v-model="form.name" class="inlineFormInput" disabled />
        </el-form-item>
        <el-form-item label="名称">
          <el-input
            v-model="form.description"
            class="inlineFormInput"
            disabled
          />
        </el-form-item>

        <el-form-item label="权限">
          <el-tree
            ref="tree"
            :data="typeList"
            :default-checked-keys="defaultChecked"
            :props="defaultProps"
            default-expand-all
            show-checkbox
            check-strictly
            node-key="id"
            @check="checkHandle"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="handelType === 'new'" type="primary" @click="handleAdd"
          >确 定</el-button
        >
        <el-button v-else type="primary" @click="handleEdit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/role";
import apiPermission from "@/api/permissionVue";


export default {
  data() {
    return {
      listData: [],
      handelType: "",
      dialogVisible: false,
      form: {
        description: "",
        id: 0,
        name: "",
        permissionIds: [],
      },
      rulesStr: "",
      typeList: [],
      defaultChecked: [],
      defaultProps: {
        children: "child",
        label: "title",
      },
    };
  },

  created() {
    this.getDataList();
    this.getRulesList();
  },
  methods: {
    async getDataList() {
      await api.getList().then((res) => {
        this.listData = res.data.data;
      });
    },
    checkHandle() {
      const halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys().join(",");
      const checkedKeys = this.$refs.tree.getCheckedKeys().join(",");
      if (halfCheckedKeys.length && checkedKeys.length) {
        this.rulesStr = halfCheckedKeys + "," + checkedKeys;
      } else if (halfCheckedKeys.length && checkedKeys.length === 0) {
        this.rulesStr = halfCheckedKeys;
      } else if (halfCheckedKeys.length === 0 && checkedKeys.length) {
        this.rulesStr = checkedKeys;
      } else {
        this.rulesStr = "";
      }
      this.form.permissionIds = this.rulesStr.split(",");
    
    },
    getRulesList() {
      apiPermission.getTreeList().then((res) => {
        this.typeList = res.data;

        console.log(this.typeList);
      });
    },
    async showEdit(obj) {
      this.handelType = "edit";
      await api.detail(obj.id).then((res) => {
        this.defaultChecked = res.data.permissionIds;
        this.form = res.data;
      });

      this.dialogVisible = true;
    },
    handleEdit() {
  
      api.update(this.form).then((res) => {
        if (res.code == 200) {
          this.$message({ message: "更新成功", type: "success" });
        } else {
          this.$message({ message: res.message, type: "error" });
        }
        this.getDataList();
        this.dialogVisible = false;
      });
    },
    showAdd() {
      this.handelType = "new";
      this.form = {};
      this.dialogVisible = true;
    },
    handleAdd() {
      api.create(this.form).then((res) => {
        if (res.code == 200) {
          this.$message({ message: "新增成功", type: "success" });
        } else {
          this.$message({ message: res.message, type: "error" });
        }
        this.getDataList();
        this.dialogVisible = false;
      });
    },
    handleDel(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        let arr = [];
        arr.push(id);

        api.remove({ ids: arr }).then((res) => {
          if (res.code == 200) {
            this.$message({ message: "删除成功", type: "success" });
          } else {
            this.$message({ message: res.message, type: "error" });
          }
          this.getDataList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.inlineForm .inlineFormSelect {
  width: 260px;
}

.inlineForm .inlineFormInput {
  width: 260px;
}

.el-form--inline .el-form-item {
  width: 48%;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  width: 400px;
}
</style>
